<template>
 <div :class="windowClass">
    <v-row no-gutters class="pa-1">
        <v-col cols="12">
        
          <v-card class="pa-2">
            <v-toolbar dense flat class="mb-2">
               <div class="text-h5"> Bets</div>
                <v-spacer/>
                <span style="width: 140px; margin-top: 24px;"> 
                     <v-menu
                        v-model="from_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                              label="From Date"
                              :value="from_date"
                              readonly
                              filled
                              dense
                              outlined
                              append-icon="mdi-calendar"
                              v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="from_date" no-title scrollable @input="from_menu=false, search=''" />
                    </v-menu>
               </span>
                 <span style="width: 140px; margin-top: 24px; margin-left: 7px;"> 
                     <v-menu
                        v-model="to_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                              label="Date"
                              :value="to_date"
                              readonly
                              filled
                              dense
                              outlined
                              append-icon="mdi-calendar"
                              v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="to_date" no-title scrollable @input="to_menu=false, get_wallet_transaction(), search=''" />
                    </v-menu>
               </span>
               <span style="width: 140px; margin-left: 7px;">
                  <v-autocomplete
                  label="Filter"
                  dense
                  v-model="game_type"
                  hide-details
                  outlined
                  @change="search=''"
                  :items="GAMESITEMS"
                  />
               </span>
                 <span style="width: 220px; margin-left: 7px;">
                  <v-text-field
                      label="Enter acct. name"
                      v-model="search"
                      dense
                      outlined
                      hide-details
                    />
               </span>
              </v-toolbar>
                
                <div id="PrintReport">
                  <div>
                    Total Bets: {{$money(TOTAL)}}<br/>
                    Total Hits: {{$money(HITS)}}<br/>
                    Date Covered: {{from_date}} - {{to_date}}<br/>
                    Filter: {{game_type}}<br/>
                    Search: {{search}}<br/>
                    Result(s): {{$commas(SEARCHTRANSACTIONS.length)}}
                  </div>
                  <table class="va-table md-font" >
                      <tr>
                          <th class="text-left">Acct. Name</th>
                          <th class="text-left">Game</th>
                          <th class="text-left">Trans. DT</th>
                          <th class="text-left">Draw DT</th>
                          <th class="text-right">AMOUNT</th>
                           <th class="text-right">HITS</th>
                      </tr>
                      <tr v-for="(item, index) in SEARCHTRANSACTIONS" :key="index">
                          <td :class="item.status=='win'?'green-bg td-'+IS_ODD(index):'td-'+IS_ODD(index)">
                                    <v-chip
                                    class="mr-2"
                                    v-if="item.status=='win'"
                                    color="success"
                                    label
                                    small
                                    text-color="white"
                                    >
                                    <v-icon small left>
                                    mdi-check
                                    </v-icon>
                                    Win
                                    </v-chip>
                               
                              {{item.account_name.toUpperCase()}} 
                          </td>
                            <td :class="item.status=='win'?'green-bg td-'+IS_ODD(index):'td-'+IS_ODD(index)">{{item.game}}</td>
                          <td :class="item.status=='win'?'green-bg td-'+IS_ODD(index):'td-'+IS_ODD(index)">{{$moment(item.transaction_date).format("MMM DD, YYYY hh:mm a")}} </td>
                           <td :class="item.status=='win'?'green-bg td-'+IS_ODD(index):'td-'+IS_ODD(index)">{{item.draw_date}}</td>
                          <td class="text-right" :class="item.status=='win'?'green-bg td-'+IS_ODD(index):'td-'+IS_ODD(index)">{{$money(item.amount)}}</td>
                           <td class="text-right" :class="item.status=='win'?'green-bg td-'+IS_ODD(index):'td-'+IS_ODD(index)">{{$money(item.hits)}}</td>
                      </tr>
                       <!-- <tr>
                          <th class="text-center lg-font" colspan="3">Tickets: {{$money(total)}} | Win: {{$money(wintotal)}}</th>
                      </tr> -->
                  </table>
                </div>
            </v-card>
        </v-col>
    </v-row>
</div>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
      to_menu: false,
      from_menu: false,
        search:'',
        from_date:'',
        game_type: 'All Games',
       
        to_date: '',
        date_range:"",
        salesman:{},
        transactions:[],
        per_type:'Overall'
    }),
     beforeCreate: function () {
      if (!this.$session.exists()) {
        this.$router.push('/')
      }
    },
    created() {
       if (this.$session.exists()) {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
           this.from_date =   this.$moment().format("YYYY-MM-DD")//this.$moment().subtract(7, "days").format("YYYY-MM-DD")
          this.to_date =  this.$moment().format("YYYY-MM-DD")
          this.get_wallet_transaction()
        } 
    },
    computed:{
         windowClass() {
        if(this.$store.state.responsive){
          return "va-reponsive"
        } else {
          return "va-not-reponsive"
        }
      },
      isOnline(){
        return true
      },
      user() {
        if(localStorage.user){
            return JSON.parse(localStorage.user)
        }
        return null
      },
      balance(){
        return  this.$store.state.balance.balance
      },
      GAMESITEMS() {
        var items =  this.$store.state.games
        items.push('WINNINGS ONLY')
        return items
      },
      SEARCHTRANSACTIONS(){
         var trans = []
        if(this.search == '') {
          trans = this.FILTERTRANSACTIONS
        } else {
           trans = this.FILTERTRANSACTIONS.reduce((res,item)=>{
              if(item.account_name.toLowerCase().includes(this.search.toLowerCase())) {
                res.push(item)
              }
              return res
           },[])
        }
           return trans
      },
      FILTERTRANSACTIONS(){
        var trans = []
         if(this.game_type == '2D') {
           trans = this.transactions.reduce((res,item)=>{
              if(item.game == "2D") {
                res.push(item)
              }
              return res
          }, [])
          return trans
        } else if(this.game_type == '3D') {
            trans = this.transactions.reduce((res,item)=>{
              if(item.game == "3D") {
                res.push(item)
              }
              return res
            }, [])
            return trans
        } else if(this.game_type == 'STL-SILAY') {
            trans = this.transactions.reduce((res,item)=>{
              if(item.game == "STL-SILAY") {
                res.push(item)
              }
              return res
            }, [])
            return trans
        } else if(this.game_type == 'STL-ILOILO-CITY') {
            trans = this.transactions.reduce((res,item)=>{
              if(item.game == "STL-ILOILO-CITY") {
                res.push(item)
              }
              return res
            }, [])
            return trans
        } else if(this.game_type == 'STL-ILOILO-PROVINCE') {
            trans = this.transactions.reduce((res,item)=>{
              if(item.game == "STL-ILOILO-PROVINCE") {
                res.push(item)
              }
              return res
            }, [])
            return trans
        } else if(this.game_type == 'PERYA-ANTIQUE') {
            trans = this.transactions.reduce((res,item)=>{
              if(item.game == "PERYA-ANTIQUE") {
                res.push(item)
              }
              return res
            }, [])
            return trans
        } else if(this.game_type == 'Winnings Only') {
            trans = this.transactions.reduce((res,item)=>{
              if(item.status == "win") {
                res.push(item)
              }
              return res
            }, [])
            return trans
        } else {
          trans = this.transactions
        }
         return trans
      },
       HITS(){
        var total = this.SEARCHTRANSACTIONS.reduce((res,item)=>{
            res+=this.$IsNum(item.hits)
            return res
        }, 0)
        return total
      },
      TOTAL(){
        var total = this.SEARCHTRANSACTIONS.reduce((res,item)=>{
            res+=this.$IsNum(item.amount)
            return res
        }, 0)
        return total
      },
       wintotal(){
        var total = this.transactions.reduce((res,item)=>{
            if(item.type == "Win") {
              res+=this.$IsNum(item.amount)
            }
            return res
        }, 0)
        return total
      },
    },
    methods: {
      ...mapMutations(['setAlert', 'setDrawer', 'setAlertColor', 'setAlertText', 'setLoggedIn', 'setOverlayLoading']),
      get_wallet_transaction(){
        this.transactions=[]
          this.$http.post("bets", {req_by:  parseInt(this.$session.get('userID')), from_date: this.from_date, to_date: this.to_date, req_role:'admin'}).then(response => {
           response.data.items != null?this.transactions =  response.data.items:this.transactions=[]
           console.log(response.data.items)
        
         }).catch(e => {
           console.log(e.data)
         })
      }
    },
  }
</script>